import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

import Container from 'components/Container/Container';
import HeroText from 'components/HeroText/HeroText';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import TextLayoutContainer from 'components/TextLayoutContainer/TextLayoutContainer';

// import { banner } from 'styles/pages/common.module.scss';

const query = graphql`
  query Secure {
    site {
      siteMetadata {
        url
        site
      }
    }
  }
`;

const AsfaleiaPages = () => {
  const {
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  return (
    <Layout fullWidth>
      <Seo title="Ασφάλεια συναλλαγών" />
      <HeroText title="ΑΣΦΑΛΕΙΑ" subtitle="ΑΣΦΑΛΕΙΑ ΣΥΝΑΛΛΑΓΩΝ" />
      <Container withPadding isContent>
        <TextLayoutContainer>
          <p>
            Το πρωτόκολλο SSL (Secure Sockets Layer), είναι σήμερα το παγκόσμιο
            standard στο διαδίκτυο για την πιστοποίηση δικτυακών τόπων
            (websites) στους δικτυακούς χρήστες και για την κρυπτογράφηση
            στοιχείων μεταξύ των διαδικτυακών χρηστών και των δικτυακών
            εξυπηρετητών (web servers). Μία κρυπτογραφημένη SSL επικοινωνία
            απαιτεί όλες τις πληροφορίες που αποστέλλονται μεταξύ ενός πελάτη
            και ενός εξυπηρετητή (server) να κρυπτογραφούνται από το λογισμικό
            αποστολής και να αποκρυπτογραφούνται από το λογισμικό αποδοχής,
            προστατεύοντας έτσι προσωπικές πληροφορίες κατά τη μεταφορά τους.
            Επιπλέον, όλες οι πληροφορίες που αποστέλλονται με το πρωτόκολλο
            SSL, προστατεύονται από έναν μηχανισμό που αυτόματα εξακριβώνει εάν
            τα δεδομένα έχουν αλλαχτεί κατά την μεταφορά.
          </p>
          <p>
            Επιπλέον, προσωπική ασφάλεια αποτελεί το password που δίνετε όταν
            γίνεστε μέλος του <Link to={info.url}>{info.site}</Link>. Για να
            παρουσιάσουμε οποιοδήποτε προσωπικό σας στοιχείο θα πρέπει πρώτα να
            δοθεί το όνομα του χρήστη και το password. Για αυτό το λόγο, πρέπει
            να φυλάτε καλά αυτά τα στοιχεία για να μην κινδυνεύσουν να πέσουν σε
            χέρια τρίτων. Επίσης σας συμβουλεύουμε να δημιουργείτε password
            χρησιμοποιώντας σύμβολα μαζί με αλφαριθμητικούς χαρακτήρες.
          </p>
          <p>
            Τέλος να υπενθυμίσουμε ότι τα στοιχεία της πιστωτικής κάρτας δεν
            αποθηκεύονται σε αποθηκευτικά μέσα της εταιρείας κατά τη διάρκεια
            της συναλλαγής αλλά καταχωρούνται απευθείας σε ασφαλές περιβάλλον
            της συνεργαζόμενης εταιρείας (Alpha e-Commerce της Alpha Bank) που
            έχει αναλάβει τη δρομολόγηση των καρτών.
          </p>
          <p>
            Όλες οι συναλλαγές που πραγματοποιείτε μέσω του{' '}
            <Link to={info.url}>{info.site}</Link>
            διέπονται από το Διεθνές και Ευρωπαϊκό δίκαιο, που ρυθμίζει θέματα
            σχετικά με το ηλεκτρονικό εμπόριο καθώς επίσης και από το Νόμο περί
            προστασίας των καταναλωτών (Ν. 2251/1994), που ρυθμίζει θέματα
            σχετικά με τις πωλήσεις από απόσταση.
          </p>
        </TextLayoutContainer>
      </Container>
    </Layout>
  );
};

export default AsfaleiaPages;
